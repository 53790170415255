import React, { Component } from 'react';
import { Checkbox } from "monday-ui-react-core";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

export class Welcome extends Component {
    static displayName = Welcome.name;

    constructor(props) {
        super(props);

        this.userLanguage = 'en';
        this.dontShowAgain = false;
        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;

        this.texts = {
            welcome: 'Welcome',
        }
        if (this.userLanguage === 'de') {
            this.texts = {
                welcome: 'Willkommen',
            }
        }

        this.state = { currentCount: 0 };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange(event) {
        //console.log(event);
        this.dontShowAgain = !this.dontShowAgain;
        monday.storage.instance.setItem('DontShowWelcome', this.dontShowAgain);
    }

    render() {
        if (this.userLanguage === 'de') {
            return (
                <div>
                    <div>
                        <p>
                            Diese App ermöglicht es dir, gesetzliche Feiertage in dieses Board einzutragen. Du kannst die Feiertage z.B. für die Ressourcenplanung nutzen.<br />
                            Um die Feiertage einzutragen, gehe wie folgt vor:
                        </p>
                        <ol>
                            <li>Wechsle zum Tab "Feiertage".</li>
                            <li>Wähle das Land aus, dessen Feiertage du eintragen möchtest.</li>
                            <li>Wähle das Jahr aus, für das du die Feiertage eintragen möchtest.</li>
                            <li>Wähle eine Datumsspalte aus, in die das Datum der Feiertage eingetragen werden soll.</li>
                            <li>Klicke auf "In Board eintragen".</li>
                        </ol>
                        <p>
                            Fertig! So einfach ist es, die Feiertage ins Board einzutragen.<br /><br />
                            ⚠️Bitte beachte: Du benötigst mindestens eine Spalte vom Typ "Datum" oder "Zeitleiste" in diesem Board.<br /><br />
                            Übrigens: Diese App bietet auch Automatisierungen für deine Boards an, z.B. um die Anzahl der Tage zwischen dem Start und Ende eines Projektes zu berechnen und dabei Feiertage zu berücksichtigen. Mehr Infos dazu findest du auf <a href="https://www.towerapps.com/help/workday-automations-holiday-calendar/workday-automations/" target="_blank" rel="noreferrer">www.towerapps.com</a>.
                        </p>
                    </div>
                    <Checkbox onChange={this.handleCheckboxChange}
                        label="Diese Willkommensseite in Zukunft nicht mehr anzeigen."
                    />
                </div>
            );
        }
        return (
            <div>
                <div>
                    <p>
                        This app allows you to add public holidays to this board. You can use these holidays, for example, for resource planning.<br />
                        To add the holidays, proceed as follows:
                    </p>
                    <ol>
                        <li>Switch to the "Public Holidays" tab.</li>
                        <li>Select the country whose holidays you want to add.</li>
                        <li>Select the year for which you want to add the holidays.</li>
                        <li>Select a date column in which the date of the holidays should be added.</li>
                        <li>Click on "Add to Board".</li>
                    </ol>
                    <p>
                        Done! It's that easy to add public holidays to the board.<br /><br />
                        ⚠️Please note: You need at least one column of the type "Date" or "Timeline" in this board.<br /><br />
                        By the way: This app also offers automations for your boards, e.g., to calculate the number of days between the start and end of a project, taking into account public holidays. You can find more information at <a href="https://www.towerapps.com/help/workday-automations-holiday-calendar/workday-automations/" target="_blank" rel="noreferrer">www.towerapps.com</a>.
                    </p>
                </div>
                <Checkbox onChange={this.handleCheckboxChange}
                    label="Don't show this welcome page in the future."
                />
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Button } from "monday-ui-react-core";
import { Dropdown } from "monday-ui-react-core";
import { Flex } from "monday-ui-react-core";
import mondaySdk from "monday-sdk-js";
import DOMPurify from 'dompurify';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class Home extends Component {
    static displayName = Home.name;    

    constructor(props) {
        super(props);

        this.years = [];
        this.currentYear = new Date().getFullYear();
        for (var i = -1; i < 5; i++) {
            var year = this.currentYear + i;
            this.years.push({ value: year.toString(), label: year.toString() });
        }
        this.userLanguage = 'en';

        this.state = {
            countries: [],
            counties: [],
            dateColumns: [],
            loading: true,
            executing: false,
            selectedCountryId: '',
            selectedCountryName: '',
            selectedCountry: undefined,
            selectedCountyId: '',
            selectedCountyName: '',
            selectedCounty: undefined,
            selectedDateColumn: undefined,
            selectedYear: this.years[1],
            publicHolidays: [],
            year: this.currentYear,
            hasNoDateColumn: false
        };

        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;

        this.texts = {
            pleaseSelect: 'Please select a country to add public holidays to this board:',
            addToBoard: 'Add to board',
            selectCountry: 'Country',
            selectCounty: 'County',
            dateColumn: 'Date column',
            date: 'Date',
            counties: 'Counties',
            successMessage: 'Public holidays created with success',
            groupAlreadyExistsMessage: 'There is already a table for this country / county. Please use existing table!',
            pleaseDontCloseText: "Please don't close this tab while public holidays are added.",
            pleaseCreateADateColumnText: 'Please first create a date column in the board!'
        }
        if (this.userLanguage === 'de') {
            this.texts = {
                pleaseSelect: 'Bitte w\u00E4hle ein Land, um die Feiertage zum Board hinzuzuf\u00FCgen:',
                addToBoard: 'In Board eintragen',
                selectCountry: 'Land',
                selectCounty: 'Bundesland',
                dateColumn: 'Datumsspalte',
                date: 'Datum',
                counties: 'Bundesl\u00E4nder',
                successMessage: 'Die Feiertage wurden erfolgreich angelegt',
                groupAlreadyExistsMessage: 'Es gibt bereits eine Tabelle für dieses Land / Bundesland. Bitte benutze die vorhandene Tabelle!',
                pleaseDontCloseText: 'Bitte schließe diesen Tab nicht solange die Feiertage angelegt werden!',
                pleaseCreateADateColumnText: 'Bitte lege zuerst eine Datumsspalte an im Board!'
            }
        }

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCountyChange = this.handleCountyChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleDateColumnChange = this.handleDateColumnChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    componentDidMount() {
        this.populateCountriesData();
        //monday.setApiVersion('2023-10');
    //    monday.get('context').then(context => {
    //        this.mondayContext = context.data;
    //        if (this.mondayContext)
    //            this.userLanguage = this.mondayContext.user.currentLanguage;
    //        console.log(this.mondayContext);
    //        this.loadColumnData();
        //    });
        this.loadColumnData();
    }

    loadColumnData() {
        if (!this.mondayContext.connected) {
            this.dateColumnsRaw = [{ id: '1', title: 'Datumsspalte', type: 'date' }, { id: '2', title: 'Zeitleiste', type: 'timerange' }];            
            var dateColumnsDemo = this.dateColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                ...this.state, dateColumns: dateColumnsDemo //, selectedDateColumn: dateColumnsDemo[0]
            });
            this.setState({
                ...this.state, selectedDateColumn: dateColumnsDemo[0]
            });
            return;
        }
        monday.api(`
query {
  complexity {
    query
    after
  }
  boards (ids: ${this.mondayContext.boardId}) { 
    id      
    name    
    columns {
        id
        title
        type
    }
  }
        }`, { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            this.mondayColumnData = columnData;
            this.dateColumnsRaw = columnData.data.boards[0].columns.filter(c => c.type === 'date' || c.type === 'timerange');
            var dateColumns = this.dateColumnsRaw.map(c => ({ value: c.id, label: c.title }));
            this.setState({
                ...this.state, dateColumns: dateColumns
            });
            // Show error when 0 date columns exist
            if (dateColumns.length === 0) {
                this.setState({
                    ...this.state, hasNoDateColumn: true
                });
            }
            // If only 1 date column exists -> select it
            if (dateColumns.length === 1) {
                this.setState({
                    ...this.state, selectedDateColumn: dateColumns[0]
                });
            }
            if (dateColumns.length > 1) {
                monday.storage.instance.getItem('DateColumn').then(res => {
                    //console.log('Remembered date column');
                    //console.log(res);
                    if (res.data) {
                        var dateColumn = dateColumns.find(dc => dc.value === res.data.value);
                        if (dateColumn) {
                            //console.log('Setting column');
                            //console.log(dateColumn);
                            this.setState({
                                ...this.state, selectedDateColumn: dateColumn
                            });
                        }
                    }
                });
            }
        });
    }

    async populateCountriesData() {
        const response = await fetch('country?UserLanguage=' + this.userLanguage +
            "&SessionToken=" + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);        
        this.setState({...this.state, countries: data, loading: false });
    }

    async handleButtonClick() {
        if (!this.mondayContext.connected) {
            alert('Nicht mit monday.com verbunden');
            return;
        }

        if (!this.mondayContext.boardId) {
            alert('Kein Board vorhanden');
            return;
        }

        monday.execute("notice", {
            message: this.texts.pleaseDontCloseText,
            type: "info", // or "error" (red), or "info" (blue)
            timeout: 3000,
        });        

        this.setState({ ...this.state, executing: true });

        this.mondayContext.boardId = DOMPurify.sanitize(this.mondayContext.boardId);

        var boardData = await monday.api(`
query {
  complexity {
    query
    after
  }
  boards (ids: ${this.mondayContext.boardId}) { 
    id      
    name    
    columns {
        id
        title
        type
    }
    groups {
        title
        id
    }
    items_page(limit: 500) {
        items { 
        id    
        name
        group {
            id
        }
        column_values { 
            id            
            text          
        }
      }
    }
  }
        }`, { apiVersion: '2023-10' });
        //console.log(boardData);
        await this.createGroup(boardData);
        this.setState({ ...this.state, executing: false });
    }

    async createGroup(boardData) {
        var countryAndCountyName = this.state.selectedCountryName;
        if (this.state.selectedCountyName)
            countryAndCountyName = this.state.selectedCountyName + ', ' + countryAndCountyName;
        var groupName = `Public holidays ${this.state.year} | ${countryAndCountyName}`;

        var existingGroup = boardData.data.boards[0].groups.find(g => g.title === groupName);
        if (existingGroup) {
            await this.createItems(boardData, existingGroup.id);
            monday.execute("notice", {
                message: this.texts.groupAlreadyExistsMessage,
                type: "success", // or "error" (red), or "info" (blue)
                timeout: 10000,
            });        
            return;
        }

        var groupCreateResult = await monday.api(`
mutation {
  complexity {
    query
    after
  }
  create_group (board_id: ${this.mondayContext.boardId}, group_name: "${groupName}") {
    id
  }
        }`, { apiVersion: '2023-10' });
        //console.log(groupCreateResult);
        var groupId = groupCreateResult.data.create_group.id;
        await this.createItems(boardData, groupId);

        monday.execute("valueCreatedForUser");
        // TODO: Translate
        // DONE: Show toast instead https://style.monday.com/?path=/docs/feedback-toast--success-message
        // DONE: Set "erstes Erfolgserlebnis" in monday monday.execute("valueCreatedForUser");
        monday.execute("notice", {
            message: this.texts.successMessage,
            type: "success", // or "error" (red), or "info" (blue)
            timeout: 10000,
        });        

        //alert('Feiertage erfolgreich angelegt im Board.');
    }

    async createItems(boardData, groupId) {
        //console.log('createItems');
        //console.log(groupId);
        var publicHolidays = this.state.publicHolidays;
        for (var i = 0; i < publicHolidays.length; i++) {
            var publicHoliday = publicHolidays[i];
            await this.createItem(boardData, groupId, publicHoliday);
        }
    }

    async createItem(boardData, groupId, publicHoliday) {
        //console.log(publicHoliday);
        var existingItem = boardData.data.boards[0].items_page.items.find(i => i.group.id === groupId && i.name === publicHoliday.localName);
        if (existingItem) {
            console.log('Item already exists');
            return;
        }
        var dateColumnId = this.state.selectedDateColumn.value;
        var dateValue = publicHoliday.date.substring(0, 10);
        var dateColumn = this.dateColumnsRaw.find(dc => dc.id === dateColumnId);
        if (!dateColumn) {
            alert('Date column not found');
            return;
        }

        groupId = DOMPurify.sanitize(groupId);
        publicHoliday.localName = DOMPurify.sanitize(publicHoliday.localName);
        dateValue = DOMPurify.sanitize(dateValue);

        var columnValues = '';
        if (dateColumn.type === 'timerange') {
            columnValues = `\\"${dateColumnId}\\" : {\\"from\\" : \\"${dateValue}\\", \\"to\\" : \\"${dateValue}\\"}`;
        }
        else {
            columnValues = `\\"${dateColumnId}\\":\\"${dateValue}\\"`;
        }

        //var columnValues = {};
        //columnValues[dateColumnId] = dateValue;
        //var columnValuesJSON = JSON.stringify(columnValues);
        //console.log(columnValuesJSON);
        // See https://community.dronahq.com/t/convert-json-to-graphql-mutation-format/1017
        var query = `
mutation {
  complexity {
    query
    after
  }
  create_item (board_id: ${this.mondayContext.boardId}, group_id: "${groupId}", item_name: "${publicHoliday.localName}", column_values: "{${columnValues}}") {
    id
  }
        }`;
        //console.log(query);
        var itemCreateResult = await monday.api(query, { apiVersion: '2023-10' });
        //console.log(itemCreateResult);
    }

    handleCountryChange(selectedCountry) {
        if (!selectedCountry) {
            this.setState({
                ...this.state, selectedCountry: selectedCountry, selectedCountryId: '', selectedCountryName: '',
                selectedCounty: undefined, selectedCountyId: '', selectedCountyName: '', counties: []
            });
        }
        this.setState({
            ...this.state,
            selectedCountry: selectedCountry, selectedCountryId: selectedCountry.value, selectedCountryName: selectedCountry.label,
            selectedCounty: undefined, selectedCountyId: '', selectedCountyName: '', counties: []
        });
        this.loadPublicHolidays(this.state.year, selectedCountry.value);
        this.loadCounties(selectedCountry.value);
    }

    handleCountyChange(selectedCounty) {
        if (!selectedCounty) {
            this.setState({ ...this.state, selectedCounty: selectedCounty, selectedCountyId: '', selectedCountyName: '' });
        }
        else
            this.setState({ ...this.state, selectedCounty: selectedCounty, selectedCountyId: selectedCounty.value, selectedCountyName: selectedCounty.label });
        this.loadPublicHolidays(this.state.year, this.state.selectedCountryId, selectedCounty.value);
    }

    handleYearChange(selectedYear) {
        var year = selectedYear ? parseInt(selectedYear.value) : this.currentYear;
        this.setState({ ...this.state, selectedYear: selectedYear, year: year });
        this.loadPublicHolidays(year, this.state.selectedCountryId, this.state.selectedCountyId);
    }

    handleDateColumnChange(selectedDateColumn) {
        this.setState({ ...this.state, selectedDateColumn: selectedDateColumn });
        monday.storage.instance.setItem('DateColumn', selectedDateColumn.value);
    }

    async loadPublicHolidays(year, countryId, countyId) {
        if (!countryId)
            return;
        year = DOMPurify.sanitize(year);
        countryId = DOMPurify.sanitize(countryId);
        countyId = DOMPurify.sanitize(countyId);
        const response = await fetch('publicholiday?countryCode=' + countryId + '&countyCode=' + countyId +
            '&year=' + year + '&UserLanguage=' + this.userLanguage +
            "&SessionToken=" + window.mondaySessionToken);
        const data = await response.json();
        data.forEach(ph => {
            var date = new Date(ph.date);
            ph.dateText = date.toLocaleDateString();
        });
        //console.log(data);
        this.setState({ ...this.state, publicHolidays: data });
    }

    async loadCounties(countryId) {
        if (!countryId)
            return;
        const response = await fetch('county?countryCode=' + countryId + '&UserLanguage = ' + this.userLanguage +
            "&SessionToken=" + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ ...this.state, counties: data });
    }

    render() {
        if (this.mondayContext.user.isViewOnly) {
            return (
                <p>I am sorry, but you don't have write rights in monday. This app requires write rights.</p>
            );
        }
        if (this.state.hasNoDateColumn) {
            return (
                <p>{this.texts.pleaseCreateADateColumnText}</p>
            );
        }

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderContent();

        return contents;
    }

    renderContent() {
        let countySelect = this.renderCountySelect();
        let columnSelect = this.renderColumnSelect();
        let table = this.renderPublicHolidayTable(this.state.publicHolidays);
        let canAddPublicHolidays = !!this.state.selectedCountryId &&  // See https://www.samanthaming.com/tidbits/19-2-ways-to-convert-to-boolean/
            !!this.state.selectedDateColumn &&
            !this.state.executing;  
        let pleaseDontCloseText = "";
        if (this.state.executing)
            pleaseDontCloseText = <p className="app-spirit-important-hint">{this.texts.pleaseDontCloseText}</p>;

        return (
            <div>
                <p>{this.texts.pleaseSelect}</p>
                {pleaseDontCloseText}
                <Flex className="app-spirit-flex">
                    <Dropdown
                        className="app-spirit-dropdown"
                        onBlur={function noRefCheck() { }}
                        onChange={this.handleCountryChange}
                        onClear={function noRefCheck() { }}
                        onFocus={function noRefCheck() { }}
                        onInputChange={function noRefCheck() { }}
                        onMenuClose={function noRefCheck() { }}
                        onMenuOpen={function noRefCheck() { }}
                        onOptionRemove={function noRefCheck() { }}
                        onOptionSelect={function noRefCheck() { }}
                        openMenuOnFocus={function noRefCheck() { }}
                        options={this.state.countries}
                        value={this.state.selectedCountry}
                        clearable={false}
                        placeholder={this.texts.selectCountry}
                    />
                    {countySelect}
                </Flex>
                <Flex>
                    <Dropdown
                        className="app-spirit-dropdown"
                        onBlur={function noRefCheck() { }}
                        onChange={this.handleYearChange}
                        onClear={function noRefCheck() { }}
                        onFocus={function noRefCheck() { }}
                        onInputChange={function noRefCheck() { }}
                        onMenuClose={function noRefCheck() { }}
                        onMenuOpen={function noRefCheck() { }}
                        onOptionRemove={function noRefCheck() { }}
                        onOptionSelect={function noRefCheck() { }}
                        openMenuOnFocus={function noRefCheck() { }}
                        options={this.years}
                        value={this.state.selectedYear}
                        clearable={false}
                        placeholder="Year"
                    />
                    {columnSelect}
                    <Button disabled={!canAddPublicHolidays} loading={this.state.executing} onClick={this.handleButtonClick}>
                        {this.texts.addToBoard}
                    </Button>
                </Flex>
                {table}
            </div>
        );
    }

    renderCountySelect() {
        if (!this.state.counties)
            return undefined;
        if (!this.state.counties.length)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleCountyChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                options={this.state.counties}
                value={this.state.selectedCounty}
                clearable={false}
                placeholder={this.texts.selectCounty}
            />
        );
    }

    renderColumnSelect() {
        if (!this.state.dateColumns)
            return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onBlur={function noRefCheck() { }}
                onChange={this.handleDateColumnChange}
                onClear={function noRefCheck() { }}
                onFocus={function noRefCheck() { }}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionRemove={function noRefCheck() { }}
                onOptionSelect={function noRefCheck() { }}
                openMenuOnFocus={function noRefCheck() { }}
                value={this.state.selectedDateColumn}
                options={this.state.dateColumns}
                clearable={false}
                placeholder={this.texts.dateColumn}
            />
        );
    }

    renderPublicHolidayTable(publicHolidays) {
        if (!publicHolidays)
            return "";
        if (publicHolidays.length < 1)
            return "";
        return (
            <table className="table table-striped" aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th className="tableDateColumn">{this.texts.date}</th>
                        <th className="tableNameColumn">Name</th>
                        <th className="tableCountiesColumn">{this.texts.counties}</th>
                    </tr>
                </thead>
                <tbody>
                    {publicHolidays.map(ph =>
                        <tr key={ph.date}>
                            <td className="right-align">{ph.dateText}</td>
                            <td>{ph.localName}</td>
                            <td>{ph.counties}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

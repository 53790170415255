import React, { Component } from 'react';
import mondaySdk from "monday-sdk-js";
import download from "downloadjs";

const monday = mondaySdk();

export class AboutUs extends Component {
    static displayName = AboutUs.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.userLanguage = 'en';
        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;
        this.incrementCounter = this.incrementCounter.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleTitleClick = this.handleTitleClick.bind(this);
    }

    handleTitleClick() {
        //console.log('handleTitleClick');
        monday.storage.instance.deleteItem('DontShowWelcome'); //setItem('DontShowWelcome', undefined);

        monday.api(`
mutation {
  set_mock_app_subscription (
       app_id: 10097574,
       partial_signing_secret: "1d832647ac",
       is_trial: true,
       plan_id: "seat_5"
  ) {
    plan_id
  }
}`).then((result) => {
            console.log('set_mock_app_subscription');
            console.log(result);
        });
    }

    async handleButtonClick() {
        // See https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
        var fileContent = await fetch('/document/Download?FileName=LetterTemplate.pdf');
        console.log(fileContent);
        var blob = await fileContent.blob();
        console.log(blob);
        download(blob);
    //    var file = window.URL.createObjectURL(blob);
    //    window.location.assign(file);
    }

    async incrementCounter() {
    //    fetch('/document/Download?FileName=LetterTemplate.pdf', {
    //        method: 'POST',
    //        headers: {
    //            'content-type': 'application/json',
    //            'X-CSRF-TOKEN': getCookies("CSRF-TOKEN")
    //        },
    //        body: JSON.stringify(data)
    //    }).then(res => return response.blob())
    //}).catch(error =>
    //    {
    //        console.log(error)
    //    });

        //var fileContent = await fetch('/document/Download?FileName=LetterTemplate.pdf');
        //console.log(fileContent);

        //return;

        //monday.get('sessionToken').then(jwtToken => {
        //    console.log(jwtToken);
        //}); 
        var jwtToken = await monday.get('sessionToken');
        console.log(jwtToken);
        const response = await fetch('document?jwtToken=' + jwtToken.data + '&BoardId=' + this.mondayContext.boardId);
        console.log(response);

        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        if (this.userLanguage === 'de') {
            return (
                <div>
                    <h1 onClick={this.handleTitleClick}>Tower Apps Ltd. 2024</h1>

                    <p>Unsere Mission ist es, hochqualitative Apps für monday.com zu entwickeln.</p>
                    <p>Dabei legen wir einen hohen Wert auf Datenschutz. Alle unsere Server sind in Deutschland gehostet und die Verarbeitung der Daten ist 100% DSGVO konform.</p>
                    <p>Der Sitz unseres Unternehmens ist in London.</p>
                    <p>Weitere Informationen findest du unter <a href="https://www.towerapps.com" target="_blank" rel="noreferrer">www.towerapps.com</a></p>
                </div>
            );
        }
        return (
            <div>
                <h1 onClick={this.handleTitleClick}>Tower Apps Ltd. 2024</h1>

                <p>Our mission is to develop high-quality apps for monday.com.</p>
                <p>We place a high value on data protection. All our servers are hosted in Europe and the processing of data is 100% GDPR compliant.</p>
                <p>The headquarters of our company is in London.</p>
                <p>For more information, visit <a href="https://www.towerapps.com" target="_blank" rel="noreferrer">www.towerapps.com</a></p>
            </div>
        );

        //<p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>
        //<button className="btn btn-primary" onClick={this.incrementCounter}>Generate</button>
        //<button className="btn btn-primary" onClick={this.handleButtonClick}>Download</button>

    }
}

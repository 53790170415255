import React, { Component } from 'react';
import { Tab, TabList, TabPanel, TabPanels, TabsContext } from "monday-ui-react-core";
import { Home } from "./components/Home";
import { Welcome } from "./components/Welcome";
import { AboutUs } from "./components/AboutUs";
import './custom.css';
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/tokens";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export default class App extends Component {
    static displayName = App.name;    

    constructor(props) {
        super(props);

        this.isAlreadyLoaded = false;
        this.state = {
            mondayContext: undefined,
            dontShowWelcome: false,
            canRender: false
        };

    //    window.onbeforeunload = function (event) {
    //        // https://stackoverflow.com/questions/2229942/how-to-block-users-from-closing-a-window-in-javascript
    //        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    //        console.log("beforeunload");
    //        event.preventDefault();
    //        event.returnValue = ""; // required in some browsers
    //        return "You have attempted to leave this page. Are you sure?"; //(event.returnValue = "");
    //    }
    }

    componentDidMount() {

        // This function is called twice -> prevent
        if (this.isAlreadyLoaded)
            return;
        this.isAlreadyLoaded = true;

        //monday.setApiVersion('2023-10');
        /* See https://developer.monday.com/apps/docs/api-reference
  "subscription" : {
      "plan_id" : "basic_tier_5",
      "is_trial" : false,
      "renewal_date" : "2021-08-27T00:00:00+00:00"
1d832647ac
  }        */

/*
query {
  app_subscription {
    plan_id
    is_trial
    billing_period
    days_left
  }
}*/

//        monday.api(`
//mutation {
//  set_mock_app_subscription (
//       app_id:10097574,
//       partial_signing_secret: "1d832647ac",
//       is_trial: true,
//       plan_id: "seat_5"
//  ) {
//    plan_id
//  }
//}`).then((result) => {
//    console.log('set_mock_app_subscription');
//    console.log(result);

//    monday.api(`
//query {
//  app_subscription {
//    plan_id
//    is_trial
//    billing_period
//    days_left
//  }
//}`).then((result) => {
//        console.log('Query result');
//    console.log(result);

//    monday.get('sessionToken')
//        .then((token) => {
//            console.log(token);
//            // send token to backend
//            // On backend, call jwt.verify(token.data, 'MY_CLIENT_SECRET') 
//        });
//    });
//});




//        monday.api(`
//query {
//  apps_monetization_status {
//    is_supported
//  }
//}`).then((result) => {
//            console.log('Query result');
//            console.log(result);
//        });


        monday.get('context').then(context => {
            //console.log(context);
            var mondayContext = context.data;
            if (mondayContext) {
                mondayContext.connected = true;

                // TODO: Check subscription is valid?

                monday.get('sessionToken')
                    .then((token) => {
                        //mondayContext.sessionToken = token.data;
                        window.mondaySessionToken = token.data;
                        //console.log(token);
                        //console.log('Token was set');
                        this.setState({ ...this.state, canRender: true });
                        // send token to backend
                        // On backend, call jwt.verify(token.data, 'MY_CLIENT_SECRET')
                    });

            }
            else {
                mondayContext = {
                    connected: false,
                    theme: "light", //"dark", //"light",
                    user: {
                        currentLanguage: "de",
                        isViewOnly: false
                    }
                }
            }
            this.setState({ ...this.state, mondayContext: mondayContext });
            window.mondayContext = mondayContext;
            this.updateTheme(mondayContext.theme);

            monday.listen("context", res => {
                //console.log('context changed');
                //console.log(res);
                var newContext = res.data;
                if (newContext) {                    
                    newContext.connected = true;
                    window.mondayContext = newContext;
                    this.updateTheme(newContext.theme);
                }
            //    monday.get('context').then(contextData2 => {
            //        console.log(contextData2);
            //        var newContext = contextData2.data;
            //        window.mondayContext = newContext;
            //    });
            });

            this.userLanguage = 'en';
            this.mondayContext = window.mondayContext;
            if (this.mondayContext)
                this.userLanguage = this.mondayContext.user.currentLanguage;

            this.texts = {
                welcome: 'Welcome',
                publicholidays: 'Public holidays',
                aboutus: 'About us'
            }
            if (this.userLanguage === 'de') {
                this.texts = {
                    welcome: 'Willkommen',
                    publicholidays: 'Feiertage',
                    aboutus: '\u00DCber uns'
                }
            }

            if (mondayContext.connected) {
                monday.storage.instance.getItem('DontShowWelcome').then(res => {
                    //console.log(res);
                    const { value } = res.data;                    
                    this.setState({ ...this.state, dontShowWelcome: value, canRender: true });
                });
            }
            else if (!mondayContext.connected)
                this.setState({ ...this.state, canRender: true });
        });
    }

    updateTheme(theme) {
        var themeName = theme + '-app-theme';
        document.getElementsByTagName("body")[0].className = themeName; //classList.add(themeName);
        // TODO: Maybe don't add multiple themes?
    }

    //renderWelcomeTab() {
    //    if (this.state.dontShowWelcome)
    //        return '';
    //    return (
    //        <Tab>
    //            {this.texts.welcome}
    //        </Tab>
    //    );
    //}

    //renderWelcomeTabPanel() {
    //    if (this.state.dontShowWelcome)
    //        return '';
    //    return (
    //        <TabPanel>
    //            <Welcome />
    //        </TabPanel>
    //    );
    //}

    render() {
        //var isLoading = !this.state.mondayContext;
        //if (!isLoading)
        //    if (this.state.canRender)

        if (!this.state.canRender)
            return (<span>Loading...</span>);

        //var welcomeTab = this.renderWelcomeTab();
        //var welcomeTabPanel = this.renderWelcomeTabPanel();

        if (this.state.dontShowWelcome) {
            return (
                <TabsContext>
                    <TabList>
                        <Tab>
                            {this.texts.publicholidays}
                        </Tab>
                        <Tab>
                            {this.texts.aboutus}
                        </Tab>
                    </TabList>
                    <TabPanels className="app-spirit-tabpanels">
                        <TabPanel>
                            <Home />
                        </TabPanel>
                        <TabPanel>
                            <AboutUs />
                        </TabPanel>
                    </TabPanels>
                </TabsContext>
            );
        }

        return (
            <TabsContext>
                <TabList>
                    <Tab>
                        {this.texts.welcome}
                    </Tab>
                    <Tab>
                        {this.texts.publicholidays}
                    </Tab>
                    <Tab>
                        {this.texts.aboutus}
                    </Tab>
                </TabList>
                <TabPanels className="app-spirit-tabpanels">
                    <TabPanel>
                        <Welcome />
                    </TabPanel>
                    <TabPanel>
                        <Home />
                    </TabPanel>
                    <TabPanel>
                        <AboutUs />
                    </TabPanel>
                </TabPanels>
            </TabsContext>
        );
    //    return (
    //        <Layout>
    //            <Routes>
    //                {AppRoutes.map((route, index) => {
    //                    const { element, ...rest } = route;
    //                    return <Route key={index} {...rest} element={element} />;
    //                })}
    //            </Routes>
    //        </Layout>
    //    );
    }
}
